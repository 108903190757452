angular.module('alarmasConfig')
    .controller('indicadoresAlarmasConfigController', ['$scope', '$routeParams', '$location', 'alarmasConfigService', 'Utilidades', function ($scope, $routeParams, $location, alarmasConfigService, Utilidades) {
        alarmasConfigService.getIndicadoresPorDespliegue($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.indicadores = data;

                                Utilidades.getNombreDespliegue($routeParams.id)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.despliegue = data;
                                });
                            });


        $scope.mostrarAlarmasIndicador = function (idIndicador) {
            $location.path("/admin/configuracion/alarmas/" + $routeParams.id + "/" + idIndicador);
        };

    }]);