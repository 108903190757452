angular.module('sensoresAdmin')
        .controller('editarSensorAdminController', ['$scope', '$routeParams', '$location', 'sensoresAdminService', function ($scope, $routeParams, $location, sensoresAdminService) {

            sensoresAdminService.getTiposSensor()
                    .then(function (response) {
                        var data = response.data;
                        $scope.tiposSensor = data;
                        return sensoresAdminService.getClasesSensor();
                    }).then(function (response) {
                        var data = response.data;
                        $scope.clasesSensor = data;
                        return sensoresAdminService.getTiposDatoLibelium()
                    }).then(function (response) {
                        var data = response.data;
                        $scope.tiposDatoLibelium = data;
                        return sensoresAdminService.getSensor($routeParams.id);
                    }).then(function (response) {
                        var data = response.data;
                        $scope.sensor = data;
                    });

            $scope.guardarSensor = function () {
                sensoresAdminService.put($scope.sensor)
			        .then(function (response) {
                        var result = response.data;
			            alert(result);
			            $location.path("/admin/sensores");
			        })
			        .catch(function (response) {
			            $scope.error = sensoresAdminService.obtenerPrimerErrorModelState(response.data);
			            alert($scope.error);
			        });
            }
           
        }]);