angular
    .module('openData')
    .factory('openDataService', ['$http', '$q', '__env', function openDataService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var openDataUrl = 'openData';

        function getMedicionesEstacion(id) {
            return $http.get(serviceUrl + openDataUrl + '/estacion/' + id);
        }
        function getNombreEstacion(id) {
            return $http.get(serviceUrl + openDataUrl + '/estacion/' + id + '/nombre');
        }

        return {
            getMedicionesEstacion: getMedicionesEstacion,
            getNombreEstacion: getNombreEstacion
        }
    }]);