angular.module('indicadoresAdmin')
    .controller('indicadoresDespliegueAdminController', ['$scope', '$location', 'indicadoresAdminService', function ($scope, $location, indicadoresAdminService) {
        indicadoresAdminService.getDespliegues()
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegues = data;
                            });

        $scope.mostrarIndicadoresPorDespliegue = function (idDespliegue) {
            $location.path("/admin/indicadores/" + idDespliegue);
        };

    }]);