angular
    .module('alarmasConfig')
    .factory('alarmasConfigService', ['$http', '$q', '__env', function alarmasConfigService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var alarmasUrl = 'alarmas';

        function getDespliegues() {
            return $http.get(serviceUrl + alarmasUrl + '/despliegues');
        }

        function getIndicadoresPorDespliegue(depliegueId) {
            return $http.get(serviceUrl + alarmasUrl + '/indicadores/' + depliegueId);
        }

        function getIndicador(id) {
            return $http.get(serviceUrl + alarmasUrl + '/configuracion/' + id);
        }

        function put(indicador) {
            return $http.put(serviceUrl + alarmasUrl + '/config/indicador', indicador);
        }
        
        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        return {
            getDespliegues: getDespliegues,
            getIndicadoresPorDespliegue: getIndicadoresPorDespliegue,
            getIndicador: getIndicador,
            put: put,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);