angular.module('modelos')
    .controller('editarController', ['$scope', '$rootScope', '$routeParams', '$location', '$http', 'modelosService', 'desplieguesService', 'Utilidades', function ($scope, $rootScope, $routeParams, $location, $http, modelosService, desplieguesService, Utilidades) {

        modelosService.getModelo($routeParams.idModelo)
            .then(function (response) {
                $scope.modelo = response.data;
            });

        modelosService.getTipoElementos()
             .then(function (response2) {
                $scope.tipoElementos = response2.data;
            });

        

        $scope.editarModelo = function () {

            $scope.modelo = {
                Nombre: document.getElementById('NombreModelo').value,
                Ruta: document.getElementById('RutaModelo').value,
                TipoElementoId: document.getElementById('TipoElementoModelo').value,
                TipoElemento: null,
                Despliegue: null,
                //Despliegue_Id: document.getElementById('DesplieguesModelo').value
                //Despliegue_Id: 1,
                Id: $routeParams.idModelo

            };

            //alert($scope.modelo.Despliegue_Id);
            //alert($scope.data);    


            modelosService.put($scope.modelo)
                .then(function (response) {
                    //alert(response.data);
                    $location.path("/admin/despliegues");
                })
                .catch(function (response) {
                    //$scope.error = desplieguesAdminService.obtenerPrimerErrorModelState(response.data);
                    alert("Error al intentar guardar el modelo");
                });

            //alert($scope.modeloNuevo.Ruta);
            //window.open($scope.modeloNuevo.Ruta);

        }


    }]);