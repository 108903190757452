angular.module('sensores', ['esri.map'])
       .controller('sensoresController', ['$scope', '$routeParams', 'sensoresService', 'Utilidades', 'Excel', function ($scope, $routeParams, sensoresService, Utilidades, Excel) {

           sensoresService.getSensoresIndicadoresByDespliegue($routeParams.id)
               .then(function (response) {
                    var data = response.data;
                    $scope.sensoresIndicadores = data;

                   angular.forEach($scope.sensoresIndicadores, function (sensorIndicador) {
                       sensorIndicador.textoFrecuencia = '';
                       sensorIndicador.textoFrecuencia = sensorIndicador.Frecuencia + ' ' + sensorIndicador.UnidadFrecuencia.Descripcion;

                       sensorIndicador.textoUmbral = Utilidades.getTextoUmbral(sensorIndicador.Umbral, sensorIndicador.ToleranciaUmbralMax, sensorIndicador.UmbralMin, sensorIndicador.ToleranciaUmbralMin, sensorIndicador.UnidadesUmbral);
                   });

                   Utilidades.getNombreDespliegue($routeParams.id)
                           .then(function (response) {
                                var data = response.data;
                                $scope.despliegue = data;
                           });
               })
           $scope.exportarExcel = function () {
               Excel.exportar('dvSensoresListado', 'tbSensores', 'Sensores');
           };

           $scope.sensorIndicadorTracker = function (sensorIndicador) {
               return sensorIndicador.SensorId + '/' + sensorIndicador.IndicadorId;
           }

       }]);