angular.module('alarmasAdmin', [])
    .controller('desplieguesAlarmasAdminController', ['$scope', '$location', 'alarmasAdminService', function ($scope, $location, alarmasAdminService) {
        alarmasAdminService.getDespliegues()
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegues = data;
                            });

        $scope.mostrarIndicadoresPorDespliegue = function (idDespliegue) {
            $location.path("/admin/alarmas/" + idDespliegue);
        };

    }]);