angular.module('openData', [])
    .controller('openDataController', ['$scope', '$routeParams', '$filter', 'openDataService', 'Utilidades', function ($scope, $routeParams, $filter, openDataService, Utilidades) {
        openDataService.getMedicionesEstacion($routeParams.eId).then(function (response) {
            var data = response.data;
            $scope.mediciones = data;
            openDataService.getNombreEstacion($routeParams.eId).then(function (response) {
                var data = response.data;
                $scope.estacion = data;
                Utilidades.getNombreDespliegue($routeParams.dId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.despliegue = data;
                    });
            });
        });
    }]);
