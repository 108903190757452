angular.module('eNaturaGIS', ['esri.map'])
    .controller('gisController', ['$routeParams','Utilidades', 'esriLoader', function ($routeParams, Utilidades, esriLoader) {
        var self = this;
        var cred = "esri_jsapi_id_manager_data"; // cookie/local storage name
        Utilidades.getNombreDespliegue($routeParams.id)
            .then(function (response) {
                self.despliegue = response.data;
            });

        self.createMap = function () {
        esriLoader.require(['esri/Map'], function (Map) {
            self.map = new Map({
                basemap: 'streets'
            });
        });
        };

        if (!esriLoader.isLoaded()) {
            esriLoader.bootstrap({
                url: 'https://js.arcgis.com/4.10'
            }).then(function () {
                self.createMap();                
            });              
        }
        else {
            self.createMap();
        }

        self.onMapLoad = function(map) {
            esriLoader.require([
                'dojo/_base/unload',
                'dojo/cookie',
                'esri/widgets/Fullscreen',
                'esri/WebScene',
                'esri/widgets/BasemapGallery',
                'esri/widgets/Expand',
                'esri/widgets/LayerList',
                'esri/widgets/Home',
                'esri/identity/IdentityManager'
            ], function (baseUnload, cookie, Fullscreen, WebScene, BasemapGallery, Expand, LayerList, Home, IdentityManager) {
                    
                    baseUnload.addOnUnload(storeCredentials);

                    loadCredentials();

                    map.ui.add(new Fullscreen({ view: map }), "top-right");
                    var basemapGallery = new BasemapGallery({
                        view: map,
                        container: document.createElement("div")
                    });

                    var bgExpand = new Expand({
                        view: map,
                        content: basemapGallery
                    });

                    map.ui.add(bgExpand, "top-right");
                    
                    var layerList = new LayerList({
                        view: map,
                        container: document.createElement("div")
                    });
                    
                    var llExpand = new Expand({
                        view: map,
                        content: layerList
                    });
                    map.ui.add(llExpand, "top-right");

                    var scene = new WebScene({
                        portalItem: {
                            id: "0f123d2e6faa4b1f8bf36b59c6cf030b"
                        }
                    });
                    
                    self.map = scene;
                    
                    scene.load().then(function () {
                        var homeWidget = new Home({
                            view: map,
                            viewpoint: scene.initialViewProperties.viewpoint
                        });
                        map.ui.add(homeWidget, "top-left");
                    });

                    function loadCredentials() {
                        var idJson, idObject;

                        if (supports_local_storage()) {
                            // read from local storage
                            idJson = window.localStorage.getItem(cred);
                        }
                        else {
                            // read from a cookie
                            idJson = cookie(cred);
                        }

                        if (idJson && idJson != "null" && idJson.length > 4) {
                            idObject = JSON.parse(idJson);
                            IdentityManager.initialize(idObject);
                        }
                        else {
                            // console.log("didn't find anything to load :(");
                        }
                    }
                    function storeCredentials() {
                        if (IdentityManager.credentials.length === 0) {
                            return;
                        }
                        var idString = JSON.stringify(IdentityManager.toJSON());          
                        if (supports_local_storage()) {
                            // use local storage
                            window.localStorage.setItem(cred, idString);
                            // console.log("wrote to local storage");
                        }
                        else {
                            // use a cookie
                            cookie(cred, idString, { expires: 1 });
                            // console.log("wrote a cookie :-/");
                        }

                    }

                    function supports_local_storage() {
                        try {
                            return "localStorage" in window && window["localStorage"] !== null;
                        } catch (e) {
                            return false;
                        }
                    }
            });
        }
    }]);
