angular.module('desplieguesAdmin', [])
    .controller('desplieguesAdminController', ['$scope', '$location', 'desplieguesAdminService', 'Excel', function ($scope, $location, desplieguesAdminService, Excel) {
        desplieguesAdminService.getDespliegues()
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegues = data;
                            });

        $scope.editarDespliegue = function (idDespliegue) {
            $location.path('/admin/despliegues/' + idDespliegue);
        };

        $scope.desplieguesSeleccionados = ""
        $scope.eliminarDespliegues = function () {
            angular.forEach($scope.despliegues, function (despliegue) {
                if (despliegue.Selected) {
                    if ($scope.desplieguesSeleccionados != "") {
                        $scope.desplieguesSeleccionados = $scope.desplieguesSeleccionados + "," + despliegue.Id
                    } else {
                        $scope.desplieguesSeleccionados = despliegue.Id
                    }
                };
            });
            desplieguesAdminService.eliminarDespliegue($scope.desplieguesSeleccionados).then(function (response) {
                alert(response.data);
                $scope.desplieguesSeleccionados = [];
                desplieguesAdminService.getDespliegues()
                    .then(function (response) {
                        var data = response.data;
                        $scope.despliegues = data;
                    });
            }).catch(function (response) {
                $scope.error = desplieguesAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        };

        $scope.exportarExcel = function () {
            Excel.exportar('dvDespliegues', 'tbDespliegues', 'Despliegues');
        };
        $scope.seleccionarTodos = function () {
            if (!$scope.seleccionadosTodos) {
                $scope.seleccionadosTodos = true;
            } else {
                $scope.seleccionadosTodos = false;
            }
            angular.forEach($scope.despliegues, function (despliegue) {
                despliegue.Selected = $scope.seleccionadosTodos;
            });
        };
    }]);