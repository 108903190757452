angular.module('modelos')
    .controller('subirController', ['$scope', '$rootScope', '$routeParams', '$location', '$http', 'modelosService', 'desplieguesService', 'Utilidades', function ($scope, $rootScope, $routeParams, $location, $http, modelosService, desplieguesService, Utilidades) {       

        //$scope.data = desplieguesService.getDespliegues();
        //desplieguesService.getDespliegues()
           // .then(function (response) {
            //    $scope.despliegues = response.data;
        //})
           // .catch(function (response){
            //alert("Great Error");
        //});
        //$scope.despliegues = data;
        //alert($scope.despliegues[0].Nombre);

        var idDespliegue = $routeParams.idDespliegue;
        //alert($routeParams.idDespliegue);

        Utilidades.getNombreDespliegue($routeParams.idDespliegue)
            .then(function (response) {
                var data = response.data;
                $scope.despliegue = data;
            });
        //alert($scope.despliegue)

        $scope.subirModelo = function () {         

            $scope.modelo = {
                Nombre: document.getElementById('NombreModelo').value,
                Ruta: document.getElementById('RutaModelo').value,
                TipoElementoId: document.getElementById('TipoElementoModelo').value,
                TipoElemento: null,
                Despliegue: null,
                //Despliegue_Id: document.getElementById('DesplieguesModelo').value
                Despliegue_Id: $routeParams.idDespliegue

            };

            //alert($scope.modelo.Despliegue_Id);
            //alert($scope.data);    


            modelosService.post($scope.modelo)
                .then(function (response) {
                    //alert(response.data);
                    $location.path("/admin/despliegues");
                })
                .catch(function (response) {
                    //$scope.error = desplieguesAdminService.obtenerPrimerErrorModelState(response.data);
                    alert("Error al intentar guardar el modelo");
                });

            //alert($scope.modeloNuevo.Ruta);
            //window.open($scope.modeloNuevo.Ruta);

        }


    }]);