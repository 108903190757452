angular.module('indicadoresAdmin')
       .controller('nuevoIndicadorAdminController', ['$scope', '$routeParams', '$location', 'indicadoresAdminService', 'sensoresAdminService', function ($scope, $routeParams, $location, indicadoresAdminService, sensoresAdminService) {
           indicadoresAdminService.getFactoresAmbientales()
                             .then(function (response) {
var data = response.data;
                                 $scope.factoresAmbientales = data;
                             });

           indicadoresAdminService.getUnidadesFrecuencia()
                             .then(function (response) {
var data = response.data;
                                 $scope.unidadesFrecuencia = data;
                             });

           indicadoresAdminService.getSensores()
                 .then(function (response) {
var data = response.data;
                     $scope.sensores = data;

                     angular.forEach($scope.sensores, function (sensor) {
                         sensor.SensoresIndicador.imagenContraste = null;
                     });
                 });

           indicadoresAdminService.getSensoresIndicadores()
                             .then(function (response) {
var data = response.data;
                                 $scope.sensoresIndicadoresImagenContraste = data;
                             });


           $scope.guardarIndicador = function () {
               $scope.sensoresIndicadores = []
               angular.forEach($scope.sensores, function (sensor) {
                   if (sensor.Selected) {
                       var imagenContrasteSensorId = null;
                       var imagenContrasteIndicadorId = null;
                       if (sensor.SensoresIndicador.imagenContraste != null) {
                           imagenContrasteSensorId = sensor.SensoresIndicador.imagenContraste.split('|')[0];
                           imagenContrasteIndicadorId = sensor.SensoresIndicador.imagenContraste.split('|')[1];
                       }
                       var sensorIndicador =
                        {
                            "SensorId": sensor.Id,
                            "Ubicacion": sensor.SensoresIndicador.Ubicacion,
                            "FrecuenciaMedicion": sensor.SensoresIndicador.FrecuenciaMedicion,
                            "UnidadFrecuenciaMedicionId": sensor.SensoresIndicador.UnidadFrecuenciaMedicionId,
                            "UnidadFrecuenciaId": sensor.SensoresIndicador.UnidadFrecuenciaId,
                            "Unidades": sensor.SensoresIndicador.Unidades,
                            "Conversion": sensor.SensoresIndicador.Conversion,
                            "SensorImagenContrasteId": imagenContrasteSensorId,
                            "IndicadorImagenContrasteId": imagenContrasteIndicadorId
                        };
                       $scope.sensoresIndicadores.push(sensorIndicador);
                   };
               });
               $scope.indicador.SensoresIndicador = $scope.sensoresIndicadores;

               $scope.indicador.DespliegueId = $routeParams.id;
               indicadoresAdminService.post($scope.indicador)
                   .then(function (response) {
                       var result = response.data;
                      alert(result);
                      $location.path("/admin/indicadores/" + $routeParams.id);
                  })
                  .catch(function (response) {
                      $scope.error = indicadoresAdminService.obtenerPrimerErrorModelState(response.data);
                      alert($scope.error);
                  });
           };
       }]);