angular.module('despliegues', ['sensores'])
       .controller('desplieguesController', ['$scope', '$location', 'desplieguesService','Excel', function ($scope, $location, desplieguesService,Excel) {



           desplieguesService.getDespliegues()
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegues = data;
                            });
           $scope.exportarExcel = function () {
               Excel.exportar('dvDespliegues', 'tbDespliegues', 'Despliegues');
           };
           $scope.verDespliegue = function (idDespliegue) {
              $location.path('/despliegues/' + idDespliegue);
              //$location.path('/documentos/' + idDespliegue);
           };
       }]);

