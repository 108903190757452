angular.module('indicadoresAdmin')
       .controller('editarIndicadorAdminController', ['$scope', '$routeParams', '$location', 'indicadoresAdminService', function ($scope, $routeParams, $location, indicadoresAdminService) {

            indicadoresAdminService.getFactoresAmbientales()
                .then(function (response) {
                    var data = response.data;
                    $scope.factoresAmbientales = data;
                    return indicadoresAdminService.getUnidadesFrecuencia();
                }).then(function (response) {
                    var data = response.data;
                    $scope.unidadesFrecuencia = data;
                    return indicadoresAdminService.getSensoresIndicadores();
                }).then(function (response) {
                    var data = response.data;
                    $scope.sensoresIndicadoresImagenContraste = data;
                    return indicadoresAdminService.getIndicador($routeParams.id);
                }).then(function (response) {
                    var data = response.data;
                    $scope.indicador = data;
                    return indicadoresAdminService.getSensores();
                }).then(function (response) {
                    var data = response.data;
                    $scope.sensores = data;

                    angular.forEach($scope.sensores, function (sensor) {
                        angular.forEach($scope.indicador.SensoresIndicador, function (sensorIndicador) {

                            if (sensor.Id == sensorIndicador.SensorId && $scope.indicador.Id == sensorIndicador.IndicadorId) {
                                sensor.SensorIndicador = [];
                                sensor.Selected = true;
                                sensor.mostrarDatos = true;

                                sensor.SensorIndicador.SensorId = sensorIndicador.SensorId;
                                sensor.SensorIndicador.Ubicacion = sensorIndicador.Ubicacion;
                                sensor.SensorIndicador.FrecuenciaMedicion = sensorIndicador.FrecuenciaMedicion;
                                sensor.SensorIndicador.UnidadFrecuenciaMedicionId = sensorIndicador.UnidadFrecuenciaMedicionId;
                                sensor.SensorIndicador.UnidadFrecuenciaId = sensorIndicador.UnidadFrecuenciaId;
                                sensor.SensorIndicador.Unidades = sensorIndicador.Unidades;
                                sensor.SensorIndicador.Conversion = sensorIndicador.Conversion;
                                sensor.SensorIndicador.SensorImagenContrasteId = sensorIndicador.SensorImagenContrasteId;
                                sensor.SensorIndicador.IndicadorImagenContrasteId = sensorIndicador.IndicadorImagenContrasteId;
                                sensor.SensorIndicador.imagenContraste = ((sensorIndicador.SensorImagenContrasteId == null) ? '' : sensorIndicador.SensorImagenContrasteId.toString()) + '|' + ((sensorIndicador.IndicadorImagenContrasteId == null) ? '' : sensorIndicador.IndicadorImagenContrasteId.toString());
                                //break;
                            }
                        });
                    });
                });

           $scope.guardarIndicador = function () {
               $scope.sensoresIndicadores = []
               angular.forEach($scope.sensores, function (sensor) {
                   if (sensor.Selected) {
                       var sensorIndicador =
                        {
                            "IndicadorId": $routeParams.id,
                            "SensorId": sensor.Id,
                            "Ubicacion": sensor.SensorIndicador.Ubicacion,
                            "FrecuenciaMedicion": sensor.SensorIndicador.FrecuenciaMedicion,
                            "UnidadFrecuenciaMedicionId": sensor.SensorIndicador.UnidadFrecuenciaMedicionId,
                            "UnidadFrecuenciaId": sensor.SensorIndicador.UnidadFrecuenciaId,
                            "Unidades": sensor.SensorIndicador.Unidades,
                            "Conversion": sensor.SensorIndicador.Conversion,
                            "SensorImagenContrasteId": (sensor.SensorIndicador.imagenContraste == null) ? null : sensor.SensorIndicador.imagenContraste.split('|')[0],
                            "IndicadorImagenContrasteId": (sensor.SensorIndicador.imagenContraste == null) ? null : sensor.SensorIndicador.imagenContraste.split('|')[1]
                        };
                       $scope.sensoresIndicadores.push(sensorIndicador);
                   };
               });
               $scope.indicador.SensoresIndicador = $scope.sensoresIndicadores;
               indicadoresAdminService.put($scope.indicador)
			   .then(function (response) {
			       alert(response.data);
			       $location.path("/admin/indicadores/" + $scope.indicador.DespliegueId);
			   })
			   .catch(function (response) {
			       $scope.error = indicadoresAdminService.obtenerPrimerErrorModelState(response.data);
			       alert($scope.error);
			   });
           }
       }]);