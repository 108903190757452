angular
    .module('sensores')
    .factory('sensoresService', ['$http', '$q', '$filter', '__env', function sensoresService($http, $q, $filter, __env) {

        var serviceUrl = __env.apiUrl;
        var sensoresIndicadoresUrl = 'sensoresindicadores';
        var sensoresUrl = 'sensores';

        function getSensoresIndicadoresByDespliegue(id) {
            return $http.get(serviceUrl + sensoresIndicadoresUrl + '/despliegue/' + id);
        }

        function getSensor(idS, idI, pageSize, pageNumber) {
            var paginacion = "";
            if (typeof pageSize !== "undefined" && typeof pageNumber !== "undefined") {
                paginacion = '/' + pageSize + '/' + pageNumber;
            }
            return $http.get(serviceUrl + sensoresUrl + '/detalle/' + idS + '/' + idI + paginacion);
        }

        function getFactoresAmbientales() {
            return $http.get(serviceUrl + 'factoresambientales');
        }

        function getArchivo(id) {
            return $http.get(serviceUrl + 'archivos/' + id);
        }

        function getMapOptions() {
            return {
                mapOptions: {
                    scrollwheel: false
                }
            };
        }

        function getInformeRuidos(sensorId, indicadorId, fechainforme) {
            return $http({
                method: 'get',
                url: serviceUrl + sensoresUrl + '/informesRuido/' + sensorId + '/' + indicadorId,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

                },
                params: {
                    //   fechainforme:   fechainforme,
                    fechainforme: (fechainforme === null) ? "" : ($filter('date')(fechainforme, "yyyy-MM-dd")).toString()
                }
            });
           // return $http.get(serviceUrl + sensoresUrl + '/informes/' + sensorId + '/' + indicadorId);
        }

        function getInformeRuidosMensual(sensorId, indicadorId, fechainforme) {
            return $http({
                method: 'get',
                url: serviceUrl + sensoresUrl + '/informesRuidoMensual/' + sensorId + '/' + indicadorId,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

                },
                params: {
                    fechainforme: (fechainforme === null) ? "" : ($filter('date')(fechainforme, "yyyy-MM-dd")).toString()
                }
            });
        }

        function getInformeAire(sensorId, indicadorId, fechainforme) {
            return $http({
                method: 'get',
                url: serviceUrl + sensoresUrl + '/informesAire/' + sensorId + '/' + indicadorId,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

                },
                params: {
                    fechainforme: (fechainforme === null) ? "" : ($filter('date')(fechainforme, "yyyy-MM-dd")).toString()
                }
            });
        }

        function getInformeAireMensual(sensorId, indicadorId, fechainforme) {
            return $http({
                method: 'get',
                url: serviceUrl + sensoresUrl + '/informesAireMensual/' + sensorId + '/' + indicadorId,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

                },
                params: {
                    fechainforme: (fechainforme === null) ? "" : ($filter('date')(fechainforme, "yyyy-MM-dd")).toString()
                }
            });
        }


         return {
            getSensoresIndicadoresByDespliegue: getSensoresIndicadoresByDespliegue,
            getSensor: getSensor,
            getFactoresAmbientales: getFactoresAmbientales,
            getArchivo: getArchivo,
            getMapOptions: getMapOptions,
            getInformeRuidos: getInformeRuidos,
            getInformeRuidosMensual: getInformeRuidosMensual,
            getInformeAire: getInformeAire,
            getInformeAireMensual: getInformeAireMensual
        }
    }]);