angular
    .module('sensoresAdmin')
    .factory('sensoresAdminService', ['$http', '$q', '__env', function sensoresAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var sensoresURL = 'sensores';

        function getSensores() {
            return $http.get(serviceUrl + sensoresURL);
        }

        function getSensor(id) {
            return $http.get(serviceUrl + sensoresURL + '/' + id);
        }

        function getTiposSensor() {
            return $http.get(serviceUrl + 'tipossensor');
        }

        function getClasesSensor() {
            return $http.get(serviceUrl + 'clasessensor');
        }

        function getTiposDatoLibelium() {
            return $http.get(serviceUrl + 'tiposdatolibelium');
        }

        function post(sensor) {
            return $http.post(serviceUrl + sensoresURL, sensor);
        }

        function put(sensor) {
            return $http.put(serviceUrl + sensoresURL, sensor);
        }

        function eliminarSensores(sensoresEliminar) {
            return $http.delete(serviceUrl + sensoresURL + '/eliminar/' + sensoresEliminar, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).catch(function (response) {
                alert('Error');
            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        return {
            getSensores: getSensores,
            getSensor: getSensor,
            getTiposSensor: getTiposSensor,
            getClasesSensor: getClasesSensor,
            getTiposDatoLibelium: getTiposDatoLibelium,
            post: post,
            put: put,
            eliminarSensores: eliminarSensores,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);