angular.module('despliegues')
    .controller('despliegueDetalleController', ['$scope', '$filter', '$routeParams', '$location', 'desplieguesService', 'Utilidades', function ($scope, $filter, $routeParams, $location, desplieguesService, Utilidades) {
        $scope.utilidades = Utilidades; // Para poder acceder desde el HTML

           if ($scope.indicadores == null) {
               desplieguesService.getIndicadoresDespliegue($routeParams.id)
                             .then(function (response) {
                                 var data = response.data;
                                 $scope.indicadoresBrutos = data;
                                 var aux = new Array();
                                 for (var i = 0; i < $scope.indicadoresBrutos.length; i++) {
                                     aux[i] = $scope.indicadoresBrutos[i][0];
                                     //aux[i].EstadoMedicion = MedicionesUtils.ObtenerEstadoMedicion(aux[i].IdIndcador, 1, aux[i].Umbral, aux[i].ToleranciaUmbralMax, aux[i].UmbralMin, aux[i].ToleranciaUmbralMin, aux[i].IdMedicion, aux[i].Valor, aux[i].Ubicacion);
                                     //aux[i].EstadoMedicion = $scope.obtenerEstadoMedicion(aux[i].Valor, aux[i].Umbral, aux[i].toleranciaUmbralMax, aux[i].umbralMin, axu[i].toleranciaUmbralMin);
                                 } 
                                 $scope.indicadores = aux;
                             });
           }
           Utilidades.getNombreDespliegue($routeParams.id)
                             .then(function (response) {
                                 var data = response.data;
                                 $scope.despliegue = data;
                             });
           desplieguesService.getFactoresAmbientales()
                             .then(function (response) {
                                 var data = response.data;
                                 $scope.factoresAmbientales = data;
            });

           
           desplieguesService.getClasesSensor()
                            .then(function (response) {
                                var data = response.data;
                                $scope.clasesSensor = data;
                            });
           $scope.showList = true;

           $scope.getDescripcionFactorAmbiental = function (idFactorAmbiental) {
               var factorAmbiental = $filter("filter")($scope.factoresAmbientales, { Id: idFactorAmbiental }, true);
               return (factorAmbiental[0].Descripcion);
        };

        
           $scope.getClaseSensor = function (idClaseSensor) {
               if (idClaseSensor == 1) return "medicion";
               if (idClaseSensor == 2) return "matricula";
               if (idClaseSensor == 3) return "foto";
               if (idClaseSensor == 4) return "estado";
           };
           $scope.getBateria = function (bateria) {
               if (bateria != null) {
                   if (bateria < 10) return "bateria-roja";
                   if (bateria < 50) return "bateria-naranja";
                   if (bateria == 100) return "bateria-full"
                   return "bateria-verde";
               } else return "bateria-off";
           };
           $scope.getDescripcionClaseSensor = function (idClaseSensor) {
               var claseSensor = $filter("filter")($scope.clasesSensor, { Id: idClaseSensor }, true);
               return (claseSensor[0].Descripcion);
           };

           $scope.getTextoUmbral = function (umbral,toleranciaUmbralMax,umbralMin,toleranciaUmbralMin,unidadesUmbral) {
               return Utilidades.getTextoUmbral(umbral, toleranciaUmbralMax, umbralMin, toleranciaUmbralMin, unidadesUmbral);
           };

           $scope.getValorMedicion = function (idClaseSensor, valor, ubicacion, estado) {
               $scope.colorMedicionStyle = Utilidades.getStyleColorMedicion(estado);
               return Utilidades.getValorMedicion(idClaseSensor, valor, ubicacion, estado);
           };

           $scope.getFecha = function (value) {
               return Utilidades.getFecha(value);
           }

           $scope.verSensorIndicador = function (idSensor, idIndicador) {
               $location.path('/indicadores/sensor/' + idSensor + '/' + idIndicador);
           };


        $scope.obtenerEstadoMedicion = function(ValorMedicion, umbralsup, tolsup, umbralinf, tolinf) {
            var strEstadoMedicion = "";
            if (tolsup == null) {
                tolsup = 0;
            }
            if (tolinf == null) {
                tolinf = 0;
            }
            if (umbralsup == null) {
                umbralsup = 999, 99;
            } else {
                umbralsup = umbralsup + tolsup;
            }
            if (umbralinf == null) {
                umbralsup = -999, 99;
            } else {
                umbralinf = umbralinf + tolinf;
            }

            if (ValorMedicion == null) {
                strEstadoMedicion = "Off";
            } else if (ValorMedicion > umbralinf && ValorMedicion < umbralsup) {
                strEstadoMedicion = "Ok";
            } else {
                strEstadoMedicion = "Alerta";
            }
            return strEstadoMedicion;
        }

        //Esta funcion hacia que los tiempos de carga de la pagina fueran eternos
        $scope.abrirMapa = function () {
                $scope.showList = false;
                 $scope.onMapLoad = onMapLoad;
                if (!esriLoader.isLoaded()) {
                    esriLoader.bootstrap({
                        url: 'https://js.arcgis.com/4.10'
                    }).then(function () {
                        createMap();
                    });
                }
                else {
                    createMap();
                }
            }
            function createMap() {
                esriLoader.require(['esri/Map'], function (Map) {
                    $scope.map = new Map({
                        basemap: 'hybrid'
                    });
                });
            }

            function onMapLoad(map) {
                esriLoader.require([
                    'esri/layers/GraphicsLayer',
                    'esri/symbols/PictureMarkerSymbol',
                    'esri/geometry/Point',
                    'esri/Graphic',
                    'esri/PopupTemplate',
                    'esri/widgets/BasemapGallery',
                    'esri/widgets/Expand',
                    'esri/widgets/Fullscreen',
                    'esri/widgets/Home'
                ], function (GraphicsLayer, PictureMarkerSymbol, Point, Graphic, PopupTemplate, BasemapGallery, Expand, Fullscreen, Home) {

                        map.ui.add(new Fullscreen({ view: map }), "top-right");
                        var basemapGallery = new BasemapGallery({
                            view: map,
                            container: document.createElement("div")
                        });

                        var bgExpand = new Expand({
                            view: map,
                            content: basemapGallery
                        });

                        map.ui.add(bgExpand, "top-right");

                    // Crear capa de gráficos
                        var cLayer = new GraphicsLayer();
                        map.map.layers.add(cLayer);

                        // Recorrer sensores y añadirlos a la capa de gráficos
                        var indicadores = $scope.indicadores;

                        for (var i = indicadores.length - 1; i >= 0; i--) {
                            const indicador = indicadores[i];
                            if (indicador.SensoresIndicador != null && indicador.SensoresIndicador.length) {
                                for (var j = indicador.SensoresIndicador.length - 1; j >= 0; j--) {
                                    const sensor = indicador.SensoresIndicador[j];
                                    var grafico = getGraficoSensor(sensor, indicador, PictureMarkerSymbol, Point, Graphic, PopupTemplate);
                                    if (grafico) {
                                        cLayer.add(grafico);
                                    }
                                }
                            }
                        };
                        // Ajustar vista
                        map.goTo(cLayer.graphics).then(function () {
                            var homeWidget = new Home({
                                view: map,
                                viewpoint: map.viewpoint
                            });
                            map.ui.add(homeWidget, "top-left");
                        });
                    }

                )
            };
            $scope.onMapLoad = onMapLoad;

            function getGraficoSensor(sensor, indicador, PictureMarkerSymbol, Point, Graphic, PopupTemplate) {
                var addSensor = false;

                // Valores por defecto, por si no hay medicion
                var valor = '';
                var urlImagen = Utilidades.obtenerRutaImagen(indicador.FactorAmbientalId, '');
                var url = "#!/indicadores/sensor/" + sensor.SensorId + "/" + sensor.IndicadorId;
                var id = 0;
                var contenido = "No hay mediciones<br/><a href={URL}>Ver Sensor</a>";
                var point = new Point({
                    x: sensor.PosicionamientoXSensor,
                    y: sensor.PosicionamientoYSensor
                });
                
                if (sensor.Mediciones != null) {
                    const medicion = sensor.Mediciones; // Sólo viene una medición

                    if (medicion.PosicionamientoXSensor && medicion.PosicionamientoYSensor) {

                        point = new Point({
                            x: medicion.PosicionamientoXSensor,
                            y: medicion.PosicionamientoYSensor
                        });

                        valor = (medicion.ValorMedicion == null) ? '' : medicion.ValorMedicion;
                        id = medicion.Id;
                        url = "#!/indicadores/sensor/" + medicion.SensorId + "/" + medicion.IndicadorId;
                        contenido = "Valor: {Valor} {Unidades}<br/><a href={URL}>Ver  Sensor</a>";
                        urlImagen = Utilidades.obtenerRutaImagen(indicador.FactorAmbientalId,
                            medicion.EstadoMedicion != null ? medicion.EstadoMedicion : '');

                        addSensor = true;
                    }
                }
                else if (sensor.PosicionamientoXSensor && sensor.PosicionamientoYSensor) {

                    addSensor = true;
                }

                if (addSensor) {

                    var marker = new PictureMarkerSymbol({
                        url: urlImagen,
                        width: 45,
                        height: 39,
                        xoffset: 8,
                        yoffset: 19
                    });
                    var title = (sensor.Ubicacion == null) ? '' : sensor.Ubicacion;
                    var infoTemplate = new PopupTemplate({
                        title: title,
                        content: contenido
                    });
                    var attr = { "Id": id, "Valor": valor, "Unidades": sensor.Unidades, "URL": url };

                    return new Graphic(point, marker, attr, infoTemplate);
                }
                return null;
            };
            
        }
       ]);