angular.module('usuariosAdmin')
       .controller('nuevoUsuarioAdminController', ['$scope', '$routeParams','$location', 'usuariosAdminService', function ($scope, $routeParams,$location, usuariosAdminService) {
           $scope.error = '';
           $scope.rolUsuario = 2;
           $scope.roles = usuariosAdminService.getUsuarios()
                             .then(function (response) {
                                 var data = response.data;
                                 $scope.usuarios = data;
                             });

           usuariosAdminService.getRoles()
                          .then(function (response) {
                              var data = response.data;
                              $scope.roles = data;
                          });

           $scope.guardarUsuario = function () {

               usuariosAdminService.post($scope.usuario,$scope.rolUsuario).then(function (response) {
                   var result = response.data;
                   alert(result);
                   $location.path("/admin/usuarios");
               }).catch(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           }
       }]);

