angular
    .module('indicadoresAdmin')
    .factory('indicadoresAdminService', ['$http', '$q', '__env', function indicadoresAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var indicadoresUrl = 'indicadores';

        function getDespliegues() {
            return $http.get(serviceUrl + indicadoresUrl + '/despliegues');
        }

        function getSensores() {
            return $http.get(serviceUrl + 'sensores/');
        }

        function getIndicadoresPorIdDespliegue(id) {
            return $http.get(serviceUrl + indicadoresUrl + '/despliegue/' + id);
        }

        function getFactoresAmbientales() {
            return $http.get(serviceUrl + 'factoresambientales');
        }

        function getUnidadesFrecuencia() {
            return $http.get(serviceUrl + 'unidadesfrecuencia');
        }

        function getIndicador(id) {
            return $http.get(serviceUrl + indicadoresUrl + '/' + id);
        }

        function getSensoresIndicadores(id) {
            return $http.get(serviceUrl + '/sensoresindicadores');
        }

        function put(indicador) {
            return $http.put(serviceUrl + indicadoresUrl, indicador);
        }

        function post(indicador) {
            return $http.post(serviceUrl + indicadoresUrl, indicador);
        }

        function eliminarIndicadores(indicadoresEliminar) {
            return $http.delete(serviceUrl + indicadoresUrl + '/eliminar/' + indicadoresEliminar, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).catch(function (response) {
                alert('Error');
            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        return {
            getDespliegues: getDespliegues,
            getSensores: getSensores,
            getIndicadoresPorIdDespliegue: getIndicadoresPorIdDespliegue,
            getFactoresAmbientales: getFactoresAmbientales,
            getUnidadesFrecuencia: getUnidadesFrecuencia,
            getIndicador: getIndicador,
            getSensoresIndicadores: getSensoresIndicadores,
            put: put,
            post: post,
            eliminarIndicadores: eliminarIndicadores,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);