angular.module('usuariosAdmin')
       .controller('usuarioAdminController', ['$scope', '$routeParams','$location', 'usuariosAdminService', function ($scope, $routeParams,$location, usuariosAdminService) {
            usuariosAdminService.getUsuario($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.usuario = data;
                            });


            usuariosAdminService.getRoles()
                            .then(function (response) {
                                var data = response.data;
                                $scope.roles = data;
                            });

            usuariosAdminService.getRolesPorUsuario($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.rolUsuario = data;
                            });
        
            $scope.editarUsuario = function () {
                usuariosAdminService.put($scope.usuario,$scope.rolUsuario).then(function (response) {
                    var result = response.data;
                    alert(result);
                    $location.path("/admin/usuarios");
                }).catch(function (response) {
                    $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                    alert($scope.error);
                });
            }
           

       }]);



