angular.module('alarmasAdmin')
    .controller('indicadoresAlarmasAdminController', ['$scope', '$routeParams', '$location', 'alarmasAdminService', 'Utilidades', function ($scope, $routeParams, $location, alarmasAdminService, Utilidades) {
        alarmasAdminService.getIndicadoresPorDespliegue($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.indicadores = data;

                                Utilidades.getNombreDespliegue($routeParams.id)
                                    .then(function (response) {
                                        var data = response.data;
                                        $scope.despliegue = data;
                                    });
                            });


        $scope.mostrarAlarmasIndicador = function (idIndicador) {
            $location.path("/admin/alarmas/" + $routeParams.id + "/" + idIndicador);
        };

    }]);