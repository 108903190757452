angular
    .module('indicadores')
    .factory('indicadoresService', ['$http', '$q', '__env', function indicadoresService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var indicadoresUrl = 'indicadores';

        function getIndicadoresByDespliegue(id) {
            return $http.get(serviceUrl + indicadoresUrl + '/despliegue/' + id + '/factorambiental');
        }

        return {
            getIndicadoresByDespliegue: getIndicadoresByDespliegue
        }
    }]);