angular.module('configuracion', [])
    .controller('configuracionController', ['$scope', '$location', function ($scope, $location) {

        $scope.mostrarMisAlarmas = function () {
            $location.path("/admin/configuracion/alarmas/");
        };
        $scope.mostrarMisDatos = function () {
            //$location.path("/admin/configuracion/datos/");
        };

    }]);