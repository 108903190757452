angular
    .module('despliegues')
    .factory('desplieguesService', ['$http', '$q', '__env', function desplieguesService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var desplieguesUrl = 'despliegues';

        function getDespliegues() {
            return $http.get(serviceUrl + desplieguesUrl);
        }

        function getIndicadoresDespliegue(id) {
            return $http.get(serviceUrl + 'indicadores/despliegue/' + id + '/sensores');
        }

        function getFactoresAmbientales() {
            return $http.get(serviceUrl + 'factoresambientales');
        }

        function getClasesSensor() {
            return $http.get(serviceUrl + 'clasessensor');
        }

        function getMapOptions() {
            return {
                mapOptions: {

                }
            };
        }

        function getCurrentLocation() {
            var deferred = $q.defer();
            navigator.geolocation.getCurrentPosition(function (position) {
                var myCurrentLocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                };
                deferred.resolve(myCurrentLocation);
            });
            return deferred.promise;
        }        

        return {
            getDespliegues: getDespliegues,
            getIndicadoresDespliegue: getIndicadoresDespliegue,
            getFactoresAmbientales: getFactoresAmbientales,
            getClasesSensor: getClasesSensor,
            getMapOptions: getMapOptions,
            getCurrentLocation: getCurrentLocation,
        }
    }]);

