angular.module('desplieguesAdmin')
    .controller('nuevoDespliegueAdminController', ['$scope', '$routeParams', '$location', 'desplieguesAdminService', 'usuariosAdminService', 'estacionesAdminService', function ($scope, $routeParams, $location, desplieguesAdminService, usuariosAdminService, estacionesAdminService) {
           $scope.error = '';
           $scope.usuarios = usuariosAdminService.getUsuarios()
                                     .then(function (response) {
                                         var data = response.data;
                                         $scope.usuarios = data;
                                         estacionesAdminService.getEstaciones()
                                             .then(function (response) {
                                                var data = response.data;
                                                $scope.estaciones = data;
                                         })
                                     });

           $scope.usuariosSeleccionarTodos = function () {
               if (!$scope.usuariosSeleccionadosTodos) {
                   $scope.usuariosSeleccionadosTodos = true;
               } else {
                   $scope.usuariosSeleccionadosTodos = false;
               }
               angular.forEach($scope.usuarios, function (usuario) {
                   usuario.Selected = $scope.usuariosSeleccionadosTodos;
               });
           };
           $scope.guardarDespliegue = function () {
               $scope.despliegue.Usuarios = [{}];
               $scope.despliegue.Estaciones = [{}];
               angular.forEach($scope.usuarios, function (usuario) {
                   if (usuario.Selected) {
                       $scope.despliegue.Usuarios.push(usuario);
                   };
               });
               angular.forEach($scope.estaciones, function (estacion) {
                   if (estacion.Selected) {
                       $scope.despliegue.Estaciones.push(estacion);
                   }
               });
               desplieguesAdminService.post($scope.despliegue)
                   .then(function (response) {
                       alert(response.data);
                       $location.path("/admin/despliegues");
                   })
                   .catch(function (response) {
                       $scope.error = desplieguesAdminService.obtenerPrimerErrorModelState(response.data);
                       alert($scope.error);
                   });
           };
       }]);