angular
    .module('modelos')
    .factory('modelosService', ['$http', '$q', '__env', function modelosService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var modelosUrl = 'modelos';

        function getModelos() {
            return $http.get(serviceUrl + modelosUrl);
        }
        function getModelosDespliegue(id) {
            return $http.get(serviceUrl +  modelosUrl + '/' + 'despliegue' + '/' + id);
        }

        function getModelo(id) {
            return $http.get(serviceUrl + modelosUrl + '/' + id);
        }

        function post(modelo) {
            return $http.post(serviceUrl + modelosUrl, modelo);
        }

        function put(modelo) {
            return $http.put(serviceUrl + modelosUrl, modelo);
        }

        function getTipoElementos() {
            return $http.get(serviceUrl + modelosUrl + '/tipos');
        }

        function eliminarModelos(modelosEliminar) {
            return $http.delete(serviceUrl + modelosUrl + '/eliminar/' + modelosEliminar, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).catch(function (response) {
                alert('Error');
            });
        }

        return {
            getModelo: getModelo,
            getModelos: getModelos,
            getModelosDespliegue: getModelosDespliegue,
            post: post,
            put: put,
            getTipoElementos: getTipoElementos,
            eliminarModelos: eliminarModelos
        }
    }]);