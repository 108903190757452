angular.module('alarmasAdmin')
    .controller('alarmasAdminController', ['$scope', '$routeParams', '$location', 'alarmasAdminService', 'Utilidades', function ($scope, $routeParams, $location, alarmasAdminService, Utilidades) {
        alarmasAdminService.getIndicador($routeParams.idI)
                             .then(function (response) {
                                 $scope.indicador = response.data;

                                 Utilidades.getNombreDespliegue($routeParams.idD)
                                 .then(function (response) {
                                     $scope.despliegue = response.data;
                                 });

                                 $scope.usuariosSeleccionadosTodosEmail = true;
                                 angular.forEach($scope.indicador.Usuarios, function (usuario) {
                                     if (!(usuario.AsignadoEmail)) {
                                         $scope.usuariosSeleccionadosTodosEmail = false;
                                         return;
                                     }
                                 });
                                 $scope.usuariosSeleccionadosTodosMovil = true;
                                 angular.forEach($scope.indicador.Usuarios, function (usuario) {
                                     if (!(usuario.AsignadoMovil)) {
                                         $scope.usuariosSeleccionadosTodosMovil = false;
                                         return;
                                     }
                                 });
                             });

        $scope.avisosComprobar = function () {
            if ($scope.indicador.Avisos == false) {
                angular.forEach($scope.indicador.Usuarios, function (usuario) {
                    usuario.AsignadoEmail = false;
                    usuario.AsignadoMovil = false;
                });
                $scope.usuariosSeleccionadosTodosEmail = false;
                $scope.usuariosSeleccionadosTodosMovil = false;
            }
        }

        $scope.usuariosSeleccionarTodosEmail = function () {
            angular.forEach($scope.indicador.Usuarios, function (usuario) {
                usuario.AsignadoEmail = $scope.usuariosSeleccionadosTodosEmail;
            });
        };
        $scope.usuariosSeleccionarTodosEmailCheckear = function () {
            $scope.usuariosSeleccionadosTodosEmail = true;
            angular.forEach($scope.indicador.Usuarios, function (usuario) {
                if (!(usuario.AsignadoEmail)) {
                    $scope.usuariosSeleccionadosTodosEmail = false;
                    return;
                }
            });
        };

        $scope.usuariosSeleccionarTodosMovil = function () {
            angular.forEach($scope.indicador.Usuarios, function (usuario) {
                usuario.AsignadoMovil = $scope.usuariosSeleccionadosTodosMovil;
            });
        };
        $scope.usuariosSeleccionarTodosMovilCheckear = function () {
            $scope.usuariosSeleccionadosTodosMovil = true;
            angular.forEach($scope.indicador.Usuarios, function (usuario) {
                if (!(usuario.AsignadoMovil)) {
                    $scope.usuariosSeleccionadosTodosMovil = false;
                    return;
                }
            });
        };

        $scope.guardarIndicador = function () {
            alarmasAdminService.put($scope.indicador).then(function (result) {
                alert(result.data);
                $location.path("/admin/alarmas");
            }).catch(function (response) {
                $scope.error = alarmasAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);