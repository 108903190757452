angular.module('sensoresAdmin', [])
       .controller('sensoresAdminController', ['$scope', '$location', 'sensoresAdminService', 'Excel', function ($scope, $location, sensoresAdminService, Excel) {
           sensoresAdminService.getSensores()
                            .then(function (response) {
                                var data = response.data;
                                $scope.sensores = data;
                            });

           $scope.nuevoSensor = function () {
               $location.path('/admin/sensores/nuevo');
           };

           $scope.mostrarDatosSensor = function (idSensor) {
               $location.path('/admin/sensores/' + idSensor);
           };

           $scope.sensoresSeleccionados = ""
           $scope.eliminarSensores = function () {
               angular.forEach($scope.sensores, function (sensor) {
                   if (sensor.Selected) {
                       if ($scope.sensoresSeleccionados != "") {
                           $scope.sensoresSeleccionados = $scope.sensoresSeleccionados + "," + sensor.Id
                       } else {
                           $scope.sensoresSeleccionados = sensor.Id
                       }
                   };
               });
               sensoresAdminService.eliminarSensores($scope.sensoresSeleccionados).then(function (response) {
                    var result = response.data;
                    alert(result);
                    $scope.sensoresSeleccionados = [];
                    sensoresAdminService.getSensores()
                        .then(function (response) {
                            var data = response.data;
                            $scope.sensores = data;
                        });
               }).catch(function (response) {
                   $scope.error = sensoresAdminService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           };

           $scope.exportarExcel = function () {
               Excel.exportar('dvSensores', 'tbSensores', 'Sensores');
           };

           $scope.seleccionarTodos = function () {
               if (!$scope.seleccionadosTodos) {
                   $scope.seleccionadosTodos = true;
               } else {
                   $scope.seleccionadosTodos = false;
               }
               angular.forEach($scope.sensores, function (sensor) {
                   sensor.Selected = $scope.seleccionadosTodos;
               });
           };

       }]);