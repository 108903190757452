angular.module('despliegues')
       .controller('despliegueController', ['$scope', '$routeParams', '$location', 'Utilidades', function ($scope, $routeParams, $location, Utilidades) {
           Utilidades.getNombreDespliegue($routeParams.id)
                             .then(function (response) {
                                var data = response.data;
                                $scope.despliegue = data;
                             });
           //$scope.cambiarHayBotonVolver();
       }]);








