angular
    .module('desplieguesAdmin')
    .factory('desplieguesAdminService', ['$http', '$q', '__env', function desplieguesAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var desplieguesUrl = 'despliegues';

        function getDespliegues() {
            return $http.get(serviceUrl + desplieguesUrl);
        }

        function getDespliegue(id) {
            return $http.get(serviceUrl + desplieguesUrl + '/' + id);
        }

        function getUsuariosPorDespliegue(id) {
            return $http.get(serviceUrl + desplieguesUrl + '/usuarios/' + id);
        }

        function put(despliegue) {
            return $http.put(serviceUrl + desplieguesUrl, despliegue);
        }

        function post(despliegue) {
            return $http.post(serviceUrl + desplieguesUrl, despliegue);
        }

        function eliminarDespliegue(desplieguesEliminar) {
            return $http.delete(serviceUrl + desplieguesUrl + '/eliminar/' + desplieguesEliminar, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).catch(function (response) {
                alert('Error');
            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        return {
            getDespliegues: getDespliegues,
            getDespliegue: getDespliegue,
            getUsuariosPorDespliegue: getUsuariosPorDespliegue,
            put: put,
            post: post,
            eliminarDespliegue: eliminarDespliegue,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);