angular.module('indicadoresAdmin', [])
    .controller('indicadoresAdminController', ['$scope', '$routeParams', '$location', 'indicadoresAdminService', 'Excel', 'Utilidades', function ($scope, $routeParams, $location, indicadoresAdminService, Excel, Utilidades) {

        $scope.idDespliegue = $routeParams.id;
        indicadoresAdminService.getIndicadoresPorIdDespliegue($routeParams.id)
                .then(function (response) {
                    var data = response.data;
                    $scope.indicadores = data;

                    angular.forEach($scope.indicadores, function (indicador) {
                        if (indicador.Avisos) {
                            indicador.TextoAviso = 'Si';
                        } else {
                            indicador.TextoAviso = 'No';
                        }
                    });

                    Utilidades.getNombreDespliegue($routeParams.id)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.despliegue = data;
                                });
                });

        $scope.nuevoIndicador = function () {
            $location.path('/admin/indicadores/nuevo/' + $routeParams.id);
        };

        $scope.mostrarDatosIndicador = function (idIndicador) {
            $location.path('/admin/indicadores/editar/' + idIndicador);
        };

        $scope.indicadoresSeleccionados = ""
        $scope.eliminarIndicadores = function () {
            angular.forEach($scope.indicadores, function (indicador) {
                if (indicador.Selected) {
                    if ($scope.indicadoresSeleccionados != "") {
                        $scope.indicadoresSeleccionados = $scope.indicadoresSeleccionados + "," + indicador.Id
                    } else {
                        $scope.indicadoresSeleccionados = indicador.Id
                    }
                };
            });
            indicadoresAdminService.eliminarIndicadores($scope.indicadoresSeleccionados).then(function (response) {
                var data = response.data;
                alert(data);
                $scope.indicadoresSeleccionados = [];
                indicadoresAdminService.getIndicadoresPorIdDespliegue($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.indicadores = data;
                        });
            }).catch(function (response) {
                $scope.error = indicadoresAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        };

        $scope.exportarExcel = function () {
            Excel.exportar('dvIndicadores', 'tbIndicadores', 'Indicadores');
        };

        $scope.seleccionarTodos = function () {
            if (!$scope.seleccionadosTodos) {
                $scope.seleccionadosTodos = true;
            } else {
                $scope.seleccionadosTodos = false;
            }
            angular.forEach($scope.indicadores, function (indicador) {
                indicador.Selected = $scope.seleccionadosTodos;
            });
        };
    }]);