angular.module('indicadores', [])
    .controller('indicadoresController', ['$scope', '$routeParams', 'indicadoresService', 'Utilidades', 'Excel', function ($scope, $routeParams, indicadoresService, Utilidades, Excel) {
        $scope.utilidades = Utilidades;

           indicadoresService.getIndicadoresByDespliegue($routeParams.id)
                .then(function (response) {
                    var data = response.data;
                    $scope.indicadores = data;
                    angular.forEach($scope.indicadores, function (indicador) {
                        indicador.frecuenciaConUnidad = ((indicador.Frecuencia==null) ? '' :  indicador.Frecuencia) + ' ' + ((indicador.UnidadFrecuencia!=null) ? indicador.UnidadFrecuencia.Descripcion : '');
                        indicador.frecuenciaConUnidad = (indicador.frecuenciaConUnidad ==' ') ? '-' : indicador.frecuenciaConUnidad;

                        indicador.textoUmbral = Utilidades.getTextoUmbral(indicador.Umbral, indicador.ToleranciaUmbralMax, indicador.UmbralMin, indicador.ToleranciaUmbralMin, indicador.UnidadesUmbral);
                        indicador.textoUmbral = (indicador.textoUmbral == '') ? '-' : indicador.textoUmbral;
                    });
                    Utilidades.getNombreDespliegue($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegue = data;
                            });
               })
           $scope.toggleMasInfo = function ($index) {
               $scope.activePosition = $scope.activePosition == $index ? -1 : $index;
           };
   
           $scope.exportarExcel = function () {
               //Correcion error exportar Indicadores
               Excel.exportar('dvIndicadoresListado', 'tbIndicadores', 'Indicadores');
           };
       }]);