angular.module('login', [])
       .controller('loginController', ['$scope', 'authenticationInfo', 'authenticationService', function ($scope, authenticationInfo, authenticationService) {
           $scope.authentication = authenticationInfo;
           $scope.usuario = authenticationInfo.userName;

           $scope.login = function () {
               return authenticationService.login({
                   userName: $scope.usuario,
                   password: $scope.password
               }).then(function () {
                   $scope.authentication = authenticationInfo;
               });
           }

           $scope.logout = function () {
               return authenticationService.logout();
           }

       }]);
