angular.module('alarmas', [])
    .controller('alarmasController', ['$scope', '$routeParams', 'alarmasService', 'Excel', 'Utilidades', function ($scope, $routeParams, alarmasService, Excel, Utilidades) {
        alarmasService.getAlarmasByDespliegue($routeParams.id)
            .then(function (response) {
                var data = response.data;
                //alert('Pasa 0');
                $scope.alarmas = data;
                $scope.criterioOrden = 'FechaAlarma';
                $scope.direccionOrden = true;
                $scope.exportarExcel = function () {
                    Excel.exportar('dvAlarmas', 'tbAlarmas', 'Alarmas');
                };
                /*angular.forEach($scope.alarmas, function (alarma) {
                    if (alarma.Medicion == null || alarma.Medicion == 'undefined') {
                        alarma.Medicion.ValorMedicion = "";
                    }
                    if (alarma.Ubicacion == null || alarma.Ubicacion == 'undefined') {
                        alarma.Ubicacion = "";
                    }
                });*/
                //alert('Pasa');
                Utilidades.getNombreDespliegue($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegue = data;
                                //alert(despliegue.Id);
                                alert(despliegue.Nombre);
                            });
            });

    }]);