angular
    .module('usuariosAdmin')
    .factory('usuariosAdminService', ['$http', '$q', '__env', function usuariosAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var usuariosUrl = 'usuarios';
        var rolesUrl = 'roles';

        function getUsuarios() {
            return $http.get(serviceUrl + usuariosUrl);
        }

        function getUsuario(id) {
            return $http.get(serviceUrl + usuariosUrl + '/' + id);
        }

        function put(usuario,idRolUsuario) {
            return $http.put(serviceUrl + usuariosUrl + '/editar/'+ idRolUsuario, usuario );
        }

        function post(usuario,idRolUsuario) {
            return $http.post(serviceUrl + usuariosUrl + '/registrar/'+ idRolUsuario,usuario);
        }

       function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
       }
       function eliminarUsuarios(usuariosEliminar) {
           return  $http.delete(serviceUrl + usuariosUrl + '/eliminar/' + usuariosEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
               alert('Error');
           });
       }

       function getRoles() {
           return $http.get(serviceUrl + rolesUrl);
       }
       function getRolesPorUsuario(id) {
           return $http.get(serviceUrl + rolesUrl + '/' + id);
       }
          
        
        return {
            getUsuarios: getUsuarios,
            getUsuario: getUsuario,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarUsuarios: eliminarUsuarios,
            getRoles: getRoles,
            getRolesPorUsuario: getRolesPorUsuario
           
        }

    }]);

