angular
    .module('alarmas')
    .factory('alarmasService', ['$http', '$q', '__env', function alarmasService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var alarmasUrl = 'alarmas';

        function getAlarmasByDespliegue(id) {
            return $http.get(serviceUrl + alarmasUrl + '/despliegue/' + id);
        }

        return {
            getAlarmasByDespliegue: getAlarmasByDespliegue
        }
    }]);