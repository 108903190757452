angular
    .module('documentos')
    .factory('documentosService', ['$http', '$q', '__env', '$rootScope',  function modelosService($http, $q, __env, $rootScope) {
        var serviceUrl = __env.apiUrl;
        var documentosUrl = 'documentos';


        function getDocumentosByInf(id) {
            return $http.get(serviceUrl + documentosUrl + '/documento/' + id);
        }

        function getDocumentosByPath(path) {
            return $http({
                method: 'get',
                url: serviceUrl + documentosUrl + '/ruta',
                params:
                {
                    'path': path
                }

            });
        }


        function descargarDocumentosByPath(path) {
            return $http({
                method: 'get',
                url: serviceUrl + documentosUrl + '/path',
                responseType: 'blob',
                headers: {
                    //'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    'Content-Type':'application/octet-stream'

                },
                params:
                {
                    'path': path
                }

            });
        }


        function descargarDocumentos(paths) {
            return $http({
                method: 'get',
                url: serviceUrl + documentosUrl + '/zip2',
                responseType: 'blob',
                headers: {
                    //'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    'Content-Type': 'application/octet-stream'

                },
                params:
                {
                    'paths': paths
                }

            });
        }


        function crearCarpeta(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + documentosUrl + '/carpeta',
                params:
                {
                    'path': path + '/' + nombre,

                }

            });
        }


        function upload(documento, ruta) {
          
            var Miurl = serviceUrl + documentosUrl + '/Upload';

            var fd = new FormData();
            //fd.append('documento', documento);
            fd.append('file',documento, documento.name);
            return $http.post(Miurl, documento, {
                transformRequest: angular.identity,
                params: { path: ruta, nombre: documento.name, tipo: documento.type},
                headers: {
                    'Content-Type': false
                }
            });


        }

        function editarDocumento(path, nombreNuevo, nombreViejo) {
            return $http({
                method: 'post',
                url: serviceUrl + documentosUrl + '/Editar',
                params:
                {
                    'ruta': path,
                    'nombreNuevo': nombreNuevo,
                    'nombreViejo': nombreViejo

                }

            });
        }

        function editarCarpeta(path, nombreNuevo, nombreViejo) {
            return $http({
                method: 'post',
                url: serviceUrl + documentosUrl + '/Carpeta',
                params:
                {
                    'ruta': path,
                    'nombreNuevo': nombreNuevo,
                    'nombreViejo': nombreViejo

                }

            });
        }


        function eliminarDocumento(path) {
            return $http({
                method: 'post',
                url: serviceUrl + documentosUrl + '/Eliminar',
                params:
                {
                    'ruta': path
                }
            });
        }

        function eliminarCarpeta(path) {
            return $http({
                method: 'post',
                url: serviceUrl + documentosUrl + '/Delete',
                params:
                {
                    'ruta': path
                }

            });
        }

        function getIcono(extension)
        {
            return $http.get(serviceUrl + documentosUrl + '/imagen/' + extension);
        }

        return {

            getDocumentosByInf: getDocumentosByInf,
            descargarDocumentosByPath: descargarDocumentosByPath,
            descargarDocumentos: descargarDocumentos,
            getDocumentosByPath: getDocumentosByPath,
            crearCarpeta: crearCarpeta,
            upload: upload,
            editarDocumento: editarDocumento,
            editarCarpeta: editarCarpeta,
            eliminarDocumento: eliminarDocumento,
            eliminarCarpeta: eliminarCarpeta,
            getIcono: getIcono
            

        }
    }]);