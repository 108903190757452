angular.module('alarmasConfig')
    .controller('alarmasConfigController', ['$scope', '$routeParams', '$location', 'alarmasConfigService', 'Utilidades', function ($scope, $routeParams, $location, alarmasConfigService, Utilidades) {
        $scope.idD = $routeParams.idD;
        alarmasConfigService.getIndicador($routeParams.idI)
                            .then(function (response) {
                                var data = response.data;
                                $scope.indicador = data;

                                Utilidades.getNombreDespliegue($routeParams.idD)
                                                    .then(function (response) {
                                                        var data = response.data;
                                                        $scope.despliegue = data;
                                                    });
                            });   

        $scope.getTextoUmbral = function (umbral, toleranciaUmbralMax, umbralMin, toleranciaUmbralMin, unidadesUmbral) {
            return Utilidades.getTextoUmbral(umbral, toleranciaUmbralMax, umbralMin, toleranciaUmbralMin, unidadesUmbral);
        };

        $scope.HayUmbral = function (umbral, umbralMin) {
            return ((umbral != null || umbralMin != null) ? true : false);
        };
        $scope.HayUmbralMax = function (umbral) {
            return ((umbral != null) ? true : false);
        };
        $scope.HayUmbralMin = function (umbralMin) {
            return ((umbralMin != null) ? true : false);
        };

        $scope.guardarIndicador = function () {
            alarmasConfigService.put($scope.indicador).then(function (result) {
                alert(result.data);
                $location.path("/admin/configuracion/alarmas");
            }).catch(function (response) {
                $scope.error = misAlarmasService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);