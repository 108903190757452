angular
    .module('estacionesAdmin')
    .factory('estacionesAdminService', ['$http', '$q', '__env', function estacionesAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var estacionesUrl = 'estacionesOpenData';
        var rolesUrl = 'roles';

        function getEstaciones() {
            return $http.get(serviceUrl + estacionesUrl);
        }

        function getEstacion(id) {
            return $http.get(serviceUrl + estacionesUrl + '/' + id);
        }

        function put(usuario, idRolUsuario) {
            return $http.put(serviceUrl + estacionesUrl + '/editar/' + idRolUsuario, usuario);
        }

        function post(usuario, idRolUsuario) {
            return $http.post(serviceUrl + estacionesUrl + '/registrar/' + idRolUsuario, usuario);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
        function eliminarEstaciones(estacionesEliminar) {
            return $http.delete(serviceUrl + estacionesUrl + '/eliminar/' + estacionesEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }

        return {
            getEstaciones: getEstaciones,
            getEstacion: getEstacion,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarEstaciones: eliminarEstaciones,

        }

    }]);

