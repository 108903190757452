angular.module('prbMatricula', [])
       .controller('prbMatriculaController', ['$scope', '$routeParams', '$location', '$http', function ($scope, $routeParams, $location, $http) {
           $scope.matriculaMedicion = {
               ImagenMatricula: null,
               FechaMedicion: '2016-05-28T00:00:00',
               Matricula1: '4914BNT',
               Fiabilidad1: 50,
               Matricula2: '4915BNT',
               Fiabilidad2: 75,
               Matricula3: '4916BNT',
               Fiabilidad3: 95
           };

           $scope.uploadFile = function (input) {
               if (input.files && input.files[0]) {
                   var reader = new FileReader();
                   reader.onload = $scope.imageIsLoaded;
                   reader.readAsDataURL(input.files[0]);
               }
           };
           $scope.imageIsLoaded = function (e) {
               $scope.$apply(function () {
                   $('#imagenSeleccionada').attr('src', e.target.result);

                   //Create a canvas and draw image on Client Side to get the byte[] equivalent
                   var canvas = document.createElement("canvas");
                   var imageElement = document.createElement("img");
                   imageElement.setAttribute('src', e.target.result);
                   canvas.width = imageElement.width;
                   canvas.height = imageElement.height;
                   var context = canvas.getContext("2d");
                   context.drawImage(imageElement, 0, 0);
                   var base64Image = canvas.toDataURL("image/jpeg");
                   $scope.matriculaMedicion.ImagenMatricula = base64Image.replace(/data:image\/jpeg;base64,/g, '');
               });
           }

           //url: 'http://37.187.152.56:8083/mediciones/matricula/7/85/skldhkjedkjf655293',
           //url: 'http://localhost:64171/mediciones/matricula/3/35/skldhkjedkjf655293',
            $scope.Enviar = function () {
               $http({
                   method: 'PUT',
                   url: 'http://localhost:64171/mediciones/matricula/3/35/skldhkjedkjf655293',
                   data: $scope.matriculaMedicion,
                   headers: { 'Content-Type': 'multipart/form-data' }
               }).then(function () {
                   alert("Ok");
               }).catch(function (response) {
                   alert("Error");
               });
           };
       }]);

