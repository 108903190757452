angular.module('sensoresAdmin')
        .controller('nuevoSensorAdminController', ['$scope', '$routeParams', '$location', 'sensoresAdminService', function ($scope, $routeParams, $location, sensoresAdminService) {
            sensoresAdminService.getTiposSensor()
                    .then(function (response) {
                        var data = response.data; 
                        $scope.tiposSensor = data;
                    });

            sensoresAdminService.getClasesSensor()
                            .then(function (response) {
                                var data = response.data; 
                                $scope.clasesSensor = data;
                            });
            sensoresAdminService.getTiposDatoLibelium()
                .then(function (response) {
                    var data = response.data;
                    $scope.tiposDatoLibelium = data;
                });


            $scope.guardarSensor = function () {
                sensoresAdminService.post($scope.sensor)
			        .then(function (response) {
                        var result = response.data; 
			            alert(result);
			            $location.path("/admin/sensores");
			        })
			        .catch(function (response) {
			            $scope.error = sensoresAdminService.obtenerPrimerErrorModelState(response.data);
			            alert($scope.error);
			        });
            };
        }]);