angular.module('despliegues')
    .controller('recursosDespliegueController', ['$scope', '$routeParams', '$location', 'Utilidades', 'modelosService', 'sensoresService', function ($scope, $routeParams, $location, Utilidades, modelosService, sensoresService) {
        Utilidades.getNombreDespliegue($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.despliegue = data;
                            comprobarSensores(data.Id);
                        });
        
        function comprobarSensores(id) {
            sensoresService.getSensoresIndicadoresByDespliegue(id)
                .then(function (response) {
                    $scope.tieneSensores = (response.data.length > 0);
                });
        }
            function tieneGIS(despliegue) {
                return despliegue != null && despliegue.RutaGIS != null && despliegue.RutaGIS.length != 0;
            }
            $scope.tieneGIS = tieneGIS;
            
            function tieneSeguimientoAnalisis(despliegue) {
                return despliegue != null && despliegue.RutaSeguimientoAnalisis != null && despliegue.RutaSeguimientoAnalisis.length != 0;
            }
            $scope.tieneSeguimientoAnalisis = tieneSeguimientoAnalisis;
            
            function tieneInformacionCampo(despliegue) {
                return despliegue != null && despliegue.RutaInformacionCampo != null && despliegue.RutaInformacionCampo.length != 0;
            }
            $scope.tieneInformacionCampo = tieneInformacionCampo;
            
            function tieneOpenData(despliegue) {
                return despliegue != null && despliegue.Estaciones.length > 0;
            }
            $scope.tieneOpenData = tieneOpenData;


            //MIKEL AGUIRREMOTA
            function tieneModelos(despliegue) {
                return despliegue != null && despliegue.ModelosDespliegue.length > 0;
            }
            $scope.tieneModelos = tieneModelos;
            
        }]);









