var env = {};
if (window) {
    //Object.assign(env, window.__env); // Esto no funciona en Cordova
    env = JSON.parse(JSON.stringify(window.__env));
}

angular.module('app', ['ngRoute', 'ng-fusioncharts', 'despliegues', 'desplieguesAdmin', 'usuariosAdmin', 'indicadoresAdmin', 'alarmasAdmin', 'sensoresAdmin', 'estacionesAdmin', 'configuracion', 'alarmasConfig', 'sensores', 'indicadores', 'alarmas', 'auth', 'login', 'home', 'openData', 'eNaturaGIS', 'angularLocalStorage', 'ui.bootstrap', 'prbMatricula', 'ngFileUpload', 'modelos', 'escenasGIS', 'documentos'])

    //.constant('serviceUrl', 'http://localhost:64171/')
    //.constant('serviceUrl', 'http://10.0.2.2:64171/') // Para Depuración Android
    //.constant('serviceUrl', 'http://ptfdesarrollo/vt4lrestapi/')
    //.constant('serviceUrl', 'http://37.187.152.56:8051/')
    .constant('__env', env)
    .config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/home', {
                templateUrl: 'app/home/home.html',
                title: 'Inicio'
            })
            .when('/admin/despliegues', {
                templateUrl: 'app/admin/despliegues/desplieguesAdmin.html',
                title: 'Gestión Despliegues'
            })
            .when('/admin/despliegues/nuevo', {
                templateUrl: 'app/admin/despliegues/nuevoDespliegueAdmin.html',
                title: 'Gestión Despliegue'
            })
            .when('/admin/despliegues/:id', {
                templateUrl: 'app/admin/despliegues/editarDespliegueAdmin.html',
                title: 'Gestión Despliegue'
            })
               .when('/admin/usuarios/nuevo', {
                   templateUrl: 'app/admin/usuarios/nuevoUsuarioAdmin.html',
                   title: 'Nuevo Despliegue'
               })
            .when('/admin/usuarios', {
                templateUrl: 'app/admin/usuarios/usuariosAdmin.html',
                title: 'Gestión Usuarios'
            })
            .when('/admin/usuarios/:id', {
                templateUrl: 'app/admin/usuarios/editarUsuarioAdmin.html',
                title: 'Gestión Usuarios'
            })
            .when('/admin/indicadores', {
                templateUrl: 'app/admin/indicadores/indicadoresDespliegueAdmin.html',
                title: 'Gestión Indicadores'
            })
            .when('/admin/indicadores/:id', {
                templateUrl: 'app/admin/indicadores/indicadoresAdmin.html',
                title: 'Gestión Indicadores'
            })
            .when('/admin/indicadores/editar/:id', {
                templateUrl: 'app/admin/indicadores/editarIndicadoresAdmin.html',
                title: 'Gestión Indicadores'
            })
            .when('/admin/indicadores/nuevo/:id', {
                templateUrl: 'app/admin/indicadores/nuevoIndicadorAdmin.html',
                title: 'Gestión Indicadores'
            })
            .when('/admin/sensores', {
                templateUrl: 'app/admin/sensores/sensoresAdmin.html',
                title: 'Gestión Sensores'
            })
            .when('/admin/sensores/nuevo', {
                templateUrl: 'app/admin/sensores/nuevoSensorAdmin.html',
                title: 'Gestión Sensores'
            })
            .when('/admin/sensores/:id', {
                templateUrl: 'app/admin/sensores/editarSensorAdmin.html',
                title: 'Gestión Sensores'
            })
           .when('/despliegues', {
               templateUrl: 'app/despliegues/despliegues.html',
               title: 'Despliegues'
            })
            .when('/despliegues/:id', {
                templateUrl: 'app/despliegues/recursosDespliegue.html',
                title: 'Despliegue'
            })
            .when('/sensoresDespliegue/:id', {
                templateUrl: 'app/despliegues/despliegue.html',
                title: 'Sensores'
            })
            .when('/despliegue/:id', {
                templateUrl: 'app/despliegues/despliegueDetalle.html',
                title: 'Despliegue'
            })
            .when('/openData/:id', {
                templateUrl: 'app/openData/openData.html',
                title: 'Open Data'
            })
            .when('/openData/:dId/estacion/:eId', {
                templateUrl: 'app/openData/medicionesOpenData.html',
                title: 'Mediciones Open Data'
            })
            .when('/informacionCampo/:id', {
                templateUrl: 'app/despliegues/despliegue.html',
                title: 'Open Data'
            })
            .when('/analisis/:id', {
                templateUrl: 'app/despliegues/despliegue.html',
                title: 'Análisis'
            })
            .when('/gis/:id', {
                templateUrl: 'app/GIS/gis.html',
                title: 'GIS'
            })
            .when('/indicadores/:id', {
                templateUrl: 'app/indicadores/indicadores.html',
                title: 'Indicadores'
            })
            .when('/sensores/:id', {
                templateUrl: 'app/sensores/sensores.html',
                title: 'Sensores'
            })
            .when('/indicadores/sensor/:idS/:idI', {
                templateUrl: 'app/sensores/sensorDetalle.html',
                title: 'Sensor'
            })
            .when('/alarmas/:id', {
                templateUrl: 'app/alarmas/alarmas.html',
                title: 'Alarmas'
            })
            .when('/about', {
                templateUrl: 'app/about.html',
                title: 'Acerca de ...'
            })
            .when('/admin/alarmas', {
                templateUrl: 'app/admin/alarmas/desplieguesAlarmasAdmin.html',
                title: 'Gestión Alarmas'
            })
            .when('/admin/alarmas/:id', {
                templateUrl: 'app/admin/alarmas/indicadoresAlarmasAdmin.html',
                title: 'Gestión Alarmas'
            })
            .when('/admin/alarmas/:idD/:idI', {
                templateUrl: 'app/admin/alarmas/alarmasAdmin.html',
                title: 'Gestión Alarmas'
            })
            .when('/admin/configuracion', {
                templateUrl: 'app/admin/configuracion/configuracion.html',
                title: 'Configuración'
            })
            .when('/admin/configuracion/alarmas', {
                templateUrl: 'app/admin/configuracion/alarmas/desplieguesAlarmasConfig.html',
                title: 'Mis Alarmas'
            })
            .when('/admin/configuracion/alarmas/:id', {
                templateUrl: 'app/admin/configuracion/alarmas/indicadoresAlarmasConfig.html',
                title: 'Mis Alarmas'
            })
            .when('/admin/configuracion/alarmas/:idD/:idI', {
                templateUrl: 'app/admin/configuracion/alarmas/alarmasConfig.html',
                title: 'Gestión Alarmas'
            })
            .when('/admin/configuracion/pass', {
                templateUrl: 'app/admin/configuracion/pass.html',
                title: 'Mi Contraseña'
            })
            .when('/admin/prbMatricula', {
                templateUrl: 'app/admin/prbMatricula.html',
                title: 'Prueba Matrícula'
            })
            .when('/modelos/modelos3D/:id', {
                templateUrl: 'app/modelos/modelos3D.html',
                title: 'Modelos 3D'
            })
            .when('/modelos/visorModelos', {
                templateUrl: 'app/modelos/visorModelos.html',
                title: 'Visor Modelos'
            })
            .when('/modelos/visorEscenasGIS/:idDespliegue/:idModelo', {
                templateUrl: 'app/modelos/visorEscenasGis.html',
                title: 'Visor Modelos'
            })
            .when('/modelos/subirModelos/:idDespliegue', {
                templateUrl: 'app/modelos/subirModelos.html',
                title: 'Subir Modelos'
            })
            .when('/modelos/editarModelos/:idModelo', {
                templateUrl: 'app/modelos/editarModelos.html',
                title: 'Editar Modelos'
            })
            .when('/documentos/:id', {
                templateUrl: 'app/GestionDocumental/GestionDocumental.html',
                title: 'Gestion Documental'
            })
            .otherwise({
                redirectTo: '/home'
            });
    }])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
    }])
    .run(['$rootScope', '$location', 'authenticationService', 'authEvents', '$http',
        function ($rootScope, $location, authenticationService, authEvents, $http) {
            $rootScope.$on(authEvents.login, function () {
                $location.path('/despliegues');
            });
            $rootScope.$on(authEvents.logout, function () {
                $location.path('/home');
            });

            document.addEventListener('deviceready', onDeviceReady, false);
            function onDeviceReady() {
                navigator.splashscreen.hide();
                document.addEventListener('backbutton', function (e) {

                    if (($location.path() == '/home' || $location.path() == '/despliegues')
                        && confirm('¿Desea salir de la aplicación?')) {
                        e.preventDefault();
                        navigator.app.exitApp();
                    }
                    else {
                        navigator.app.backHistory();
                    }
                }, false);
            }
        }])
    .directive('ngTextoConfirmar', [
        function () {
            return {
                link: function (scope, element, attr) {
                    var msg = attr.ngTextoConfirmar || "¿Desea completar la operación?";
                    var clickAction = attr.ngClickConfirmado;
                    element.bind('click', function (event) {
                        if (window.confirm(msg)) {
                            scope.$apply(clickAction)
                        }
                    });
                }
            };
        }])
        .factory('Utilidades', ['$http', function ($http) {
        var interfaz = {
            getFecha: function (value) {
                if (value != null && value.length) {
                    var fechaFormateada = new Date(value);
                    var fechaActual = new Date();
                    var diferencia = Math.abs(fechaActual - fechaFormateada.getTime());
                    if (diferencia < 3600000) { // menos de una hora
                        return "Hace " + Math.round(diferencia / 60000) + " min";
                    } else if (diferencia < 86400000) { // menos de un día
                        if (fechaActual.getDay() == fechaFormateada.getDay()) {
                            return "Hoy " + formatDate(fechaFormateada, "HH:mm");
                        } else {
                            return "Ayer " + formatDate(fechaFormateada, "HH:mm");
                        }
                    }
                    return formatDate(fechaFormateada, "dd NNN HH:mm");
                }
            },
            getValorMedicion: function (idClaseSensor, valor, ubicacion, estado) {
                switch (idClaseSensor) {
                    case 1:
                        //Numérica
                        return valor;
                        break;
                    case 2:
                        //Matrícula
                        return ubicacion;
                        break;
                    case 3:
                        //Imagen
                        return estado;
                    case 4:
                        //Estado
                        return estado;
                    default:
                        return valor;
                }
            },
            getStyleColorMedicion: function (estado) {
                switch (estado) {
                    case 'Ok':
                        return { color: '#198E50' };
                        break;
                    case 'Off':
                        return {color: '#777777'};
                        break;
                    case 'Alerta':
                        return {color: '#DF3333'};
                        break;
                    default:
                        return {color: '#777777'};
                }
            },
            getTextoUmbral: function (umbral, toleranciaUmbralMax, umbralMin, toleranciaUmbralMin, unidadesUmbral) {
                var textoUmbral = '';
                if (umbral == null) {
                    if(umbralMin == null){
                        textoUmbral = '';
                    }else{
                        textoUmbral = umbralMin + ((toleranciaUmbralMin == null) ? '' : ' (-' + toleranciaUmbralMin + ')') + ' <';
                    }
                } else {
                    if (umbralMin == null) {
                        textoUmbral = '<' + umbral + ((toleranciaUmbralMax == null) ? '' : ' (+' + toleranciaUmbralMax + ')');
                    } else {
                        textoUmbral = umbralMin + ((toleranciaUmbralMin == null) ? '' : ' (-' + toleranciaUmbralMin + ')');
                        textoUmbral += ' - ';
                        textoUmbral += umbral + ((toleranciaUmbralMax == null) ? '' : ' (+' + toleranciaUmbralMax + ')');
                    }
                }
                if (unidadesUmbral != null) textoUmbral += ' ' + unidadesUmbral;
                return textoUmbral;
            },

            getNombreDespliegue: function (idDespliegue) {
                return $http.get(__env.apiUrl + 'despliegues/' + idDespliegue + '/nombre');
            },
            getClaseFactorAmbiental: function (idFactorAmbiental) {
                switch (idFactorAmbiental) {
                    case 1:
                        return "icon_agua";
                    case 2:
                        return "icon_aire";
                    case 3:
                        return "icon_fauna";
                    case 4:
                        return "icon_geologia";
                    case 5:
                        return "icon_residuos";
                    case 6:
                        return "icon_ruido";
                    case 7:
                        return "icon_suelos";
                    case 8:
                        return "icon_vegetacion";
                    case 9:
                        return "icon_vibraciones";
                    case 10:
                        return "icon_panoramica";
                };
            },
            obtenerRutaImagen: function (factorAmbiental, ultimoEstado) {

                var ruta = "images/";

                switch (factorAmbiental) {
                    case 1:
                        ruta += "agua";
                        break;
                    case 2:
                        ruta += "aire";
                        break;
                    case 3:
                        ruta += "fauna";
                        break;
                    case 4:
                        ruta += "geologia";
                        break;
                    case 5:
                        ruta += "residuos";
                        break;
                    case 6:
                        ruta += "ruido";
                        break;
                    case 7:
                        ruta += "suelos";
                        break;
                    case 8:
                        ruta += "vegetacion";
                        break;
                    case 9:
                        ruta += "vibraciones";
                        break;
                    case 10:
                        ruta += "panoramica";
                        break;
                    default:
                        ruta += "default";
                        break;
                }

                if (ultimoEstado == "Ok") ruta += "_verde";
                else if (ultimoEstado == "Alerta") ruta += "_rojo";
                else ruta += "_gris";

                return ruta + ".png";
            }

        }
        return interfaz;
    }])
    .factory("Excel", function()
    {
        var uri = 'data:application/vnd.ms-excel;base64,'
              , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head> <meta http-equiv="content-type" content="text/plain; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table  border="1">{table}</table></body></html>'
              , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
              , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }  
        return {
            exportar: function (div, table, name) {
                //Si el navegador es IE
                if (navigator.msSaveBlob) {
                    return navigator.msSaveBlob(new Blob([document.getElementById(div).innerHTML], {
                               type: "application/vnd.ms-excel"
                    }), name + ".xls");
                }
                else {
                    if (!table.nodeType) table = document.getElementById(table)
                    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
                    var link = document.createElement("a");
                        link.download = name+ ".xls";
                        link.href = uri + base64(format(template, ctx));
                        link.click();
                }
                        
            }

        }
    });

   



        
