angular.module('desplieguesAdmin')
       .controller('editarDespliegueAdminController', ['$scope', '$routeParams', 'modelosService','$location', 'desplieguesAdminService', 'usuariosAdminService', 'estacionesAdminService', function ($scope, $routeParams, modelosService, $location, desplieguesAdminService, usuariosAdminService, estacionesAdminService) {

           //alert($routeParams.id);


           desplieguesAdminService.getDespliegue($routeParams.id)
                             .then(function (response) {
                                 var data = response.data;
                                 $scope.despliegue = data;
                                 estacionesAdminService.getEstaciones()
                                     .then(function (response) {
                                         var data = response.data;
                                         $scope.estaciones = data;
                                         angular.forEach($scope.estaciones, function (estacion) {
                                             angular.forEach($scope.despliegue.Estaciones, function (estacionDespliegue) {
                                                 if (estacion.Id == estacionDespliegue.Id) {
                                                     estacion.Selected = true;
                                                 }
                                             })


                                         });
                                         usuariosAdminService.getUsuarios()
                                             .then(function (response) {
                                                 var data = response.data;
                                                 $scope.usuarios = data;
                                                 angular.forEach($scope.usuarios, function (usuario) {
                                                     angular.forEach($scope.despliegue.Usuarios, function (usuarioIndicador) {
                                                         if (usuario.Id == usuarioIndicador.Id) {
                                                             usuario.Selected = true;
                                                         }
                                                     });
                                                 });
                                             });
                                    });
               });

           modelosService.getModelosDespliegue($routeParams.id)
               .then(function (response) {
                   $scope.modelos = response.data;
                   angular.forEach($scope.modelos, function (modelo) {
                               modelo.Selected = false;
                           })
               });

           $scope.idDespliegue = $routeParams.id;
           //alert($scope.idDespliegue);

           $scope.subirModelo = function () {
               $location.path('modelos/subirModelos/' + $scope.idDespliegue);
           }

           $scope.editarModelo = function (idModelo) {
               $location.path('modelos/editarModelos/' + idModelo);
           }

           $scope.usuariosSeleccionarTodos = function () {
               if (!$scope.usuariosSeleccionadosTodos) {
                   $scope.usuariosSeleccionadosTodos = true;
               } else {
                   $scope.usuariosSeleccionadosTodos = false;
               }
               angular.forEach($scope.usuarios, function (usuario) {
                   usuario.Selected = $scope.usuariosSeleccionadosTodos;
               });
           };

           $scope.estacionesSeleccionarTodas = function () {
               if (!$scope.estacionesSeleccionadosTodas) {
                   $scope.estacionesSeleccionadosTodas = false;
               } else {
                   $scope.estacionesSeleccionadosTodas = true;
               }
               angular.forEach($scope.estaciones, function (estacion) {
                   estacion.Selected = $scope.estacionesSeleccionadosTodas;
               });
           };

           $scope.modelosSeleccionarTodos = function () {
               if (!$scope.modelosSeleccionadosTodos) {
                   $scope.modelosSeleccionadosTodos = true;
               } else {
                   $scope.modelosSeleccionadosTodos = false;
               }
               angular.forEach($scope.modelos, function (modelo) {
                   modelo.Selected = $scope.modelosSeleccionadosTodos;
               });
           };

         
           $scope.guardarDespliegue = function () {
               $scope.despliegue.Usuarios.length = 0;
               $scope.despliegue.Estaciones.length = 0;
               angular.forEach($scope.usuarios, function (usuario) {
                   if (usuario.Selected) {
                       $scope.despliegue.Usuarios.push(usuario);
                   };
               });
               angular.forEach($scope.estaciones, function (estacion) {
                   if (estacion.Selected) {
                       $scope.despliegue.Estaciones.push(estacion);
                   }
               });
               desplieguesAdminService.put($scope.despliegue)
                    .then(function (response) {
                        alert(response.data);
                        $location.path("/admin/despliegues");
                    })
                    .catch(function (response) {
                        $scope.error = desplieguesAdminService.obtenerPrimerErrorModelState(response.data);
                        alert($scope.error);
                    });
           }

           $scope.modelosSeleccionados = ""
           $scope.eliminarModelo = function () {
               angular.forEach($scope.modelos, function (modelo) {
                   if (modelo.Selected) {
                       if ($scope.modelosSeleccionados != "") {
                           $scope.modelosSeleccionados = $scope.modelosSeleccionados + "," + modelo.Id
                       } else {
                           $scope.modelosSeleccionados = modelo.Id
                       }
                   };
               });
               modelosService.eliminarModelos($scope.modelosSeleccionados).then(function (response) {
                   alert(response.data);
                   $scope.modelosSeleccionados = [];
                   modelosService.getModelosDespliegue($routeParams.id)
                       .then(function (response) {
                           var data = response.data;
                           $scope.modelos = data;
                       });
               }).catch(function (response) {
                   alert("Great Error");
               });
           };

       }]);