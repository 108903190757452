angular.module('app')
       .controller('navigationBarController', ['$scope', '$location', function ($scope, $location) {
           $scope.HayBotonVolver = false;
           $scope.cambiarHayBotonVolver = function (/*valor*/) {
               //$scope.HayBotonVolver = valor;
               alert('ya');
           };
           $scope.volver = function () {
               alert('nav');
           };
       }]);



