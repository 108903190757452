angular.module('modelos', [])
    .controller('modelosController', ['$scope', '$rootScope', '$routeParams', '$location', 'modelosService', 'Utilidades', function ($scope, $rootScope, $routeParams, $location, modelosService, Utilidades) {

        Utilidades.getNombreDespliegue($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.despliegue = data;
            });

        modelosService.getModelosDespliegue($routeParams.id)
            .then(function (response) {
                $scope.modelos = response.data;
            });

        $scope.mostrarModelo = function (modelo) {
            $rootScope.modeloRuta = modelo.Ruta;
            $rootScope.despliegueId = $scope.despliegue.Id;

            switch (modelo.TipoElemento.Id) {
                case 0:
                    $location.path('/modelos/visorModelos/');
                    break;
                case 1:
                    window.open(modelo.Ruta);
                    break;
                case 2:
                    $location.path('/modelos/visorEscenasGIS/' + $scope.despliegue.Id + '/' + modelo.Id);
                    break;
            }
        }
    }
    ]);
