angular.module('alarmasConfig', [])
    .controller('desplieguesAlarmasConfigController', ['$scope', '$location', 'alarmasConfigService', function ($scope, $location, alarmasConfigService) {
        alarmasConfigService.getDespliegues()
                            .then(function (response) {
                                var data = response.data;
                                $scope.despliegues = data;

                                if ($scope.despliegues.length == 1) {
                                    $location.path("/admin/configuracion/alarmas/" + $scope.despliegues[0].Id);
                                }
                            });

        $scope.mostrarIndicadoresPorDespliegue = function (idDespliegue) {
            $location.path("/admin/configuracion/alarmas/" + idDespliegue);
        };

    }]);