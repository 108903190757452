angular.module('modelos')
    .controller('visorController', ['$scope', '$rootScope', '$sce', '$location',  function ($scope, $rootScope, $sce, $location) {

        $scope.modeloUrl = $sce.trustAsResourceUrl($rootScope.modeloRuta);
        //$scope.Id = $rootScope.despliegueId 
        //alert($scope.modeloUrl); 

        $scope.ListaModelos = function () {
            //alert("Great Success");
            //alert($rootScope.modeloRuta)
            $location.path('/modelos/modelos3D/' + $rootScope.despliegueId);
            //alert($scope.modeloUrl);
        };

    }
        ]);
